@import "../../../../_theme_variables";
/* BLOGS */
.blog-content-wrap{
  text-align: justify;
}
.blogs-container{
  padding:rem(30px);
  @include media-breakpoint-down(md){
    padding:rem(10px);
  }
  background-color:$white;
  .secondary-blog{
    .blog-item{
      margin-top: rem(50px);
    }
  }
}
.blog-item{
  .blog-item{
    padding: 0px rem(30px);
  }
  .blog-meta{
    color: #999;
    font-size:rem(11px);
    text-transform: uppercase;
    font-weight:500;
    padding-top:rem(7px);
    span{
      @include rtl-padding-right(10px);
      display: inline-block;
      font-style: italic;
      a{
        color: #999;
      }
    }
    .blog-ctncomment{
      text-transform: capitalize;
    }
    .blog-hit{
      text-transform: capitalize;
    }
  }
  .title{
      font-size: rem(16px);
      font-weight: 500;
      color: $text-color-primary;
      padding-top: rem(15px);
      a{
        color: $text-color-primary;
        &:hover{
          color: $text-color-secondary;
        }
      }
  }
  .blog-shortinfo{
    font-size: rem(16px);
    color: #666;
    padding-top:rem(10px);
    .blog-more{
      border: 1px solid #ddd;
      @include border-radius(rem(25px));
      color: #666666;
      margin-top: rem(20px);
      padding: rem(8px) rem(20px);
      text-transform: uppercase;
      font-size: rem(11px);
      font-weight: 700;
      &:hover{
        background-color:$theme-color-tertiary;
        border: 1px solid transparent;
        color: $white;
      }
    }
  }
}
.blog-image {
    text-align: center;
}
.recent-title-category-blog{
    margin: 30px 0;
}

/* BLOG DETAIL */

.blog-detail{
  background-color:$white;
  padding:rem(30px);
  .social-share{
    margin: 20px 0;
    div{
      display: inline-block;
      &.social-heading{
        font-weight: 700;
      }
    }
  }
  .blog-tags{
    span{
      font-weight:700;
    }
    a{
      border: 1px solid #dddddd;
      padding: 2px 10px;
    }
  }
  .extra-blogs {
    border-top: 1px solid #dddddd;
    margin-top: 25px;
    padding-top: 20px;
    font-size: 16px;
  }
  .blog-comment-block{
    .comments{
      .comment-item{
        border: 1px solid #dddddd;
        margin-bottom: 10px;
        padding: 25px;
        img{
          @include border-radius(50%);
          @include rtl-margin-right(10px);
        }
        .comment-wrap{
          .comment-meta{
            font-weight: 300;
            font-size: 16px;
          }
        }
      }
    }
    .form-horizontal{
      .ipts-captcha{
        img{
          padding-bottom:10px;
          @include rtl-float-left();
        }
      }
      .text-center{
        text-align: center;
        .btn-comment{
          background-color:$theme-color-tertiary;
          color: $white;
        }
      }
    }
  }
}